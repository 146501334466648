import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import asyncComponent from './components/async.component';
import NoLayout from './layouts/layout-none/layout-none.component';
import Paymachine from './components/layouts/paymachine/layout-paymachine.component';
import r from './config/routes';
// TRY to authenticate
import TryToAuthenticate from './components/TryToAuthenticate/container';

// MAIN APP NAVS
const AsyncLoginPage = asyncComponent(() => import('./components/pages/Login/component'));
const AsyncRegisterPage = asyncComponent(() => import('./components/pages/Register/component'));
const AsyncRegisterConfirmPage = asyncComponent(() => import('./components/pages/RegisterConfirm/component'));
const AsyncForgotPasswordPage = asyncComponent(() => import('./components/pages/ForgotPassword/component'));
const AsyncForgotPasswordConfirmPage = asyncComponent(() => import('./components/pages/ForgotPasswordConfirm/component'));

const AsyncClientsEditPage = asyncComponent(() => import('./components/pages/ClientsEdit/container'));

const AsyncPayrollEditPage = asyncComponent(() => import('./components/pages/PayrollEdit/container'));

const AsyncClientsUsersCreatePage = asyncComponent(() => import('./components/pages/ClientsUsersCreate/container'));
const AsyncClientsUsersEditPage = asyncComponent(() => import('./components/pages/ClientsUsersEdit/container'));

const AsyncPaymentRequestsListPage = asyncComponent(() => import('./components/pages/PaymentRequestsList/container'));
const AsyncPaymentRequestDetailsPage = asyncComponent(() => import('./components/pages/PaymentRequestDetails/container'));

const AsyncLoansListPage = asyncComponent(() => import('./components/pages/LoansList/container'));
const AsyncLoanDetailsPage = asyncComponent(() => import('./components/pages/LoansDetails/container'));

const AsyncFeesEditPage = asyncComponent(() => import('./components/pages/FeesEdit/container'));
const AsyncSettingsNotificationsPage = asyncComponent(() => import('./components/pages/SettingsNotifications/container'));


const AsyncNotFound = asyncComponent(() => import('./components/pages/NotFound/NotFound.component'));

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

const PaymachineLayout = props => (
  <Paymachine>{props.children}</Paymachine>
);

// TODO: Consider looping through an object containing all routes
export default ({ childProps, layout }) => {
  let activeLayout;
  switch (layout.currentLayout) {
    case 'paymachine':
      activeLayout = PaymachineLayout;
      break;
    default:
      activeLayout = PaymachineLayout;
  }

  return (
    <Router>
      <TryToAuthenticate>
        <Switch>
          <AppRoute path={r.Home} exact component={AsyncClientsEditPage} props={childProps} layout={activeLayout} />
          <AppRoute path={r.Login} exact component={AsyncLoginPage} props={childProps} layout={NoLayout} />
          <AppRoute path={r.Register} exact component={AsyncRegisterPage} props={childProps} layout={NoLayout} />
          <AppRoute
            path={r.RegisterConfirm}
            exact
            component={AsyncRegisterConfirmPage}
            props={childProps}
            layout={NoLayout}
          />
          <AppRoute
            path={r.ForgotPassword}
            exact
            component={AsyncForgotPasswordPage}
            props={childProps}
            layout={NoLayout}
          />
          <AppRoute
            path={r.ForgotPasswordConfirm}
            exact
            component={AsyncForgotPasswordConfirmPage}
            props={childProps}
            layout={NoLayout}
          />

          <AppRoute
            path={r.ClientsEdit}
            exact
            component={AsyncClientsEditPage}
            props={childProps}
            layout={activeLayout}
          />
          <AppRoute
            path={r.ClientsEditPaymentMethodACH}
            exact
            component={AsyncClientsEditPage}
            props={childProps}
            layout={activeLayout}
          />

          <AppRoute
            path={r.ClientsUsersCreate}
            exact
            component={AsyncClientsUsersCreatePage}
            props={childProps}
            layout={activeLayout}
          />
          <AppRoute
            path={r.ClientsUsersEdit}
            exact
            component={AsyncClientsUsersEditPage}
            props={childProps}
            layout={activeLayout}
          />

          <AppRoute path={r.PayrollEdit} exact component={AsyncPayrollEditPage} props={childProps} layout={activeLayout} />

          <AppRoute
            path={r.PaymentRequestsList}
            exact
            component={AsyncPaymentRequestsListPage}
            props={childProps}
            layout={activeLayout}
          />

          <AppRoute
            path={r.PaymentRequestsDetails}
            exact
            component={AsyncPaymentRequestDetailsPage}
            props={childProps}
            layout={activeLayout}
          />

          <AppRoute
            path={r.LoansList}
            exact
            component={AsyncLoansListPage}
            props={childProps}
            layout={activeLayout}
          />

          <AppRoute
            path={r.LoansDetails}
            exact
            component={AsyncLoanDetailsPage}
            props={childProps}
            layout={activeLayout}
          />

          <AppRoute path={r.FeesEdit} exact component={AsyncFeesEditPage} props={childProps} layout={activeLayout} />
          <AppRoute path={r.SettingsNotifications} exact component={AsyncSettingsNotificationsPage} props={childProps} layout={activeLayout} />

          <AppRoute component={AsyncNotFound} layout={activeLayout} />
        </Switch>
      </TryToAuthenticate>
    </Router>
  );
};
