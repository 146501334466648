import { API_URL } from './env';

export default {
  Home: '/',
  Dashboard: '/',
  Login: '/login',
  Register: '/register',
  RegisterConfirm: '/register-confirm/:email/:code',
  ForgotPassword: '/forgot-password',
  ForgotPasswordConfirm: '/forgot-password-confirm/:email/:code',
  SuperAdminsList: '/super-admins',
  SuperAdminsCreate: '/super-admins/new',
  SuperAdminsEdit: '/super-admins/:super_admin_id',
  ClientsList: '/clients',
  ClientsCreate: '/clients/new',
  ClientsEdit: '/clients/:client_id',
  ClientsEditPaymentMethodACH: '/clients/:client_id/configure-ach',
  PayrollEdit: '/clients/:client_id/payroll',
  FeesEdit: '/clients/:client_id/fees',
  SettingsNotifications: '/settings/notifications',
  ClientsUsersCreate: '/clients/:client_id/users/new',
  ClientsUsersEdit: '/clients/:client_id/users/:client_user_id',
  TermsAndConditionsEdit: '/settings/terms-and-conditions',
  PaymentRequestsList: '/instant-pay-requests',
  PaymentRequestsDetails: '/instant-pay-requests/:payment_request_id',
  APIPaymentRequestsListCSV: `${API_URL}/clients/:client_id/payment-requests.csv?_pmatoken=:auth_token`,
  APIClientsUsersListCSV: `${API_URL}/clients-users/:client_id/users.csv?_pmatoken=:auth_token`,
  LoansList: '/loans',
  LoansListCSV: `${API_URL}/clients-loans/:client_id/traditional-loans.csv?_pmatoken=:auth_token`,
  LoansDetails: '/loans/:loan_id'
};
