import { connect } from 'react-redux';
import Component from './component';
import { login } from '../../../redux/auth/actions';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  login: (username, password) => dispatch(login(username, password))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component);
