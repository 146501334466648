import * as types from './types';
import _ from 'underscore';

const initial = {
  addedNew: false,
  user: null,
  list: [],
  message: null, // {msg: 'User authenticated', type: 'info'}
};

const superAdmins = (state = initial, action) => {
  switch (action.type) {
  case types.GET_SUPER_ADMINS_REQUEST_SUCCESS:
    return {
      ...state,
      addedNew: false,
      list: (_.isArray(action.payload.data) ? action.payload.data : [])
    };
  case types.CREATE_SUPER_ADMINS_REQUEST_SUCCESS:
    return {
      ...state,
      addedNew: true,
    };
  case types.CREATE_SUPER_ADMINS_REQUEST_FAILURE:
    return {
      ...state,
      message: action.payload.message
    };
  default:
    return state;
  }
};

export default superAdmins;
