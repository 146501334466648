import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from '../redux/auth/reducer';
import breadcrumbs from '../redux/breadcrumbs/reducer';
import clients from '../redux/clients/reducer';
import clientsUsers from '../redux/clients-users/reducer';
import settings from '../redux/settings/reducer';
import superAdmins from '../redux/super-admins/reducer';
import paymentRequests from '../redux/payment-requests/reducer';
import appState from '../redux/app-state/reducer';
import clientsSettings from '../redux/clients-settings/reducer';
import loans from '../redux/loans/reducer';

import layout from '../redux/theme/layout.reducer';
import theme from '../redux/theme/theme.reducer';

export default history => combineReducers({
  router: connectRouter(history),
  appState,
  auth,
  breadcrumbs,
  clients,
  clientsUsers,
  superAdmins,
  settings,
  paymentRequests,
  layout,
  theme,
  clientsSettings,
  loans
});
