/* eslint-disable import/prefer-default-export */
import { RSAA } from 'redux-api-middleware';
import * as types from './types';

import { API_URL } from '../../config/env';

export const getInstantPay = (clientId, instantpayId) => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_INSTANTPAY_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_INSTANTPAY_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_INSTANTPAY_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients/${clientId}/payment-requests/${instantpayId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const getPaymentRequests = clientId => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_PAYMENT_REQUESTS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_PAYMENT_REQUESTS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_PAYMENT_REQUESTS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients/${clientId}/payment-requests`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const approve = (clientId, id) => ({
  [RSAA]: {
    types: [
      {
        type: types.APPROVE_PAYMENT_REQUESTS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.APPROVE_PAYMENT_REQUESTS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.APPROVE_PAYMENT_REQUESTS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients/${clientId}/approve-payment-requests`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      loan_id: id
    })
  }
});

export const denyPaymentRequests = (clientId, ids) => ({
  [RSAA]: {
    types: [
      {
        type: types.DENY_PAYMENT_REQUESTS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.DENY_PAYMENT_REQUESTS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.DENY_PAYMENT_REQUESTS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients/${clientId}/deny-payment-requests`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      ids
    })
  }
});

export const deletePaymentRequests = (clientId, ids) => ({
  [RSAA]: {
    types: [
      {
        type: types.DELETE_PAYMENT_REQUESTS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.DELETE_PAYMENT_REQUESTS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.DELETE_PAYMENT_REQUESTS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients/${clientId}/payment-requests`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      ids
    })
  }
});

export const markRepaidPaymentRequests = (clientId, ids) => ({
  [RSAA]: {
    types: [
      {
        type: types.MARK_REPAID_PAYMENT_REQUESTS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.MARK_REPAID_PAYMENT_REQUESTS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.MARK_REPAID_PAYMENT_REQUESTS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients/${clientId}/mark-repaid-payment-requests`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      ids
    })
  }
});

export const markDeliveredSelected = (clientId, ids) => ({
  [RSAA]: {
    types: [
      {
        type: types.MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients/${clientId}/deliver-payment-requests`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      ids
    })
  }
});

export const getInstantpayQRCode = (clientId, instantpayId) => {
  return {
    [RSAA]: {
      types: [
        {
          type: types.GET_INSTANTPAY_QRCODE,
          payload: (_action, _state, res) => res.json()
        },
        {
          type: types.GET_INSTANTPAY_QRCODE_SUCCESS,
          payload: (_action, _state, res) => res.json()
        },
        {
          type: types.GET_INSTANTPAY_QRCODE_FAILURE,
          payload: (_action, _state, res) => res.json()
        }
      ],
      endpoint: `${API_URL}/clients/${clientId}/payment-requests/${instantpayId}/qrcode.png`,
      method: 'GET',
      headers: {
        'Content-Type': 'applicaiton/json',
        Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
      },
    }
  };
};
