import React from 'react';

import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LogOutIcon from '@material-ui/icons/PowerSettingsNew';

import SettingsIcon from '@material-ui/icons/Settings';
import { deleteToken } from '@paymachine/shared/lib/utils/push-notifications';

import { themes } from './themes';

import r from './config/routes';

export const configuredTheme = themes.theme;

export const configuredLayout = {
  currentLayout: 'paymachine',
  notificationsOpen: false,
  sidenavVariant: 'persistent'
};

const iconStyle = {
  fontSize: 20
};

export const menuItems = [
  {
    title: 'QUICK ACCESS',
    type: 'header'
  },
  {
    title: 'Dashboard',
    icon: <DashboardIcon style={iconStyle} />,
    href: `${r.ClientsEdit}#users`
  },
  {
    title: 'Users',
    icon: <AccountCircleIcon style={iconStyle} />,
    children: [{
      title: 'All Users',
      href: `${r.ClientsEdit}#users`
    },
    {
      title: 'Add New User',
      href: `${r.ClientsUsersCreate}`
    }]
  },
  {
    title: 'Instant Payroll Status',
    icon: <img alt='Icon bell' style={{ width: '21px', height: '21px', fill: 'white' }} src="/assets/images/icon_bell.svg" />,
    href: r.PaymentRequestsList,
    enable_for_loan_type: 'instantpay'
  },
  {
    title: 'Loans Status',
    icon: <img alt='Icon bell' style={{ width: '21px', height: '21px', fill: 'white' }} src="/assets/images/icon_bell.svg" />,
    href: r.LoansList,
    enable_for_loan_type: 'loan'
  },
  {
    title: 'Settings',
    icon: <SettingsIcon style={iconStyle} />,
    children: [{
      title: 'Edit Profile',
      href: r.ClientsEdit
    },
    {
      title: 'Payroll',
      href: r.PayrollEdit
    },
    {
      title: 'Fees',
      href: r.FeesEdit
    },
    {
      title: 'Notifications',
      href: r.SettingsNotifications
    }]
  },
  {
    title: 'Log out',
    icon: <LogOutIcon style={iconStyle} />,
    href: '#',
    style: {
      // position: 'absolute',
      // bottom: 0
    },
    onClick: async (e) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        await deleteToken();
      } catch (err) {
        console.error(err);
      } finally {
        localStorage.removeItem('AUTH_TOKEN');
        localStorage.removeItem('AUTH_USERNAME');
        localStorage.removeItem('AUTH_CLIENT_ID');
        localStorage.removeItem('AUTH_ROLE');
        localStorage.removeItem('AUTH_ARN');
        localStorage.removeItem('AUTH_LOGIN_TIME');

        window.location.href = '/';
      }
    }
  }
];
