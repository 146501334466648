import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import AppBar from '@material-ui/core/AppBar';
import { requestPermission, messaging } from '@paymachine/shared/lib/utils/push-notifications';
import { registerFcmToken } from '@paymachine/shared/lib/api';
import PushNotification from '@paymachine/shared/lib/components/PushNotification.component';

import NotificationSidenav from '../../../layouts/components/notification-sidenav/notification-sidenav.component';

import ContentToolbar from '../components/content-toolbar/container';
import MenuSidenav from '../components/menu-sidenav/MenuSidenav.container';
import Sidenav from '../components/sidenav/sidenav.component';

import { setSidenavOpen, toggleSidenavVariant } from '../../../actions/layout.actions';
import { styles } from '../../../themes';

import scss from './layout-paymachine.module.scss';

const ClassicLayout = (props) => {
  const {
    children,
    openSidenav,
    closableSideNav,
    persistentSideNav,
    classes,
    registerToken,
    width
  } = props;

  useEffect(() => {
    const handleFcmRefresh = async () => {
      const token = await requestPermission();
      if (token) {
        await registerToken(token);
      }
    };
    handleFcmRefresh();
  }, []);

  useEffect(() => {
    if (isWidthUp('lg', width)) {
      openSidenav();
      persistentSideNav();
    } else {
      closableSideNav();
    }
  }, [width]);

  return (
    <div className={classNames(scss['layout-paymachine-wrapper'], classes.mainLayout)}>
      <Sidenav>
        <MenuSidenav />
      </Sidenav>
      <main className={scss['layout-paymachine-main']}>
        <AppBar color="default" position="static">
          <ContentToolbar />
        </AppBar>
        <PushNotification fcm={messaging()} />
        <div className={scss['layout-paymachine-content-wrapper']}>
          <div className={scss['layout-paymachine-content']}>
            {children}
          </div>
        </div>
      </main>
      <NotificationSidenav />
    </div>
  );
};

const mapStateToProps = state => ({
  layout: {
    sidenavOpen: state.layout.sidenavOpen
  }
});

const mapDispatchToProps = dispatch => ({
  openSidenav: () => dispatch(setSidenavOpen(true)),
  closeSideNav: () => dispatch(setSidenavOpen(false)),
  closableSideNav: () => dispatch(toggleSidenavVariant('temporary')),
  persistentSideNav: () => dispatch(toggleSidenavVariant('permanent')),
  registerToken: token => registerFcmToken(localStorage.getItem('AUTH_CLIENT_ID'), localStorage.getItem('AUTH_ID'), token)
});

ClassicLayout.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  children: PropTypes.shape({}).isRequired
};

export default compose(
  withWidth(),
  withStyles(styles, { useTheme: true }),
  connect(mapStateToProps, mapDispatchToProps)
)(ClassicLayout);
