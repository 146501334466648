"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.printErrors = exports.hasErrors = void 0;

var _lodash = require("lodash");

var hasErrors = function hasErrors(error) {
  return error !== null && error !== undefined;
};

exports.hasErrors = hasErrors;

var printErrors = function printErrors(error) {
  if ((0, _lodash.isArray)(error)) {
    return error.join('. ');
  }

  return error;
};

exports.printErrors = printErrors;