import * as types from './types';
// import _ from 'underscore';

const initial = {
  message: null, // {msg: 'User authenticated', type: 'info'}
  loading: false
};

const clientsSettings = (state = initial, action) => {
  switch (action.type) {
  case types.UPDATE_CLIENTS_CONFIRMATION_TEXTS_SETTINGS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.UPDATE_CLIENTS_CONFIRMATION_TEXTS_SETTINGS_REQUEST_SUCCESS:
    return {
      ...state,
      list: action.payload.data,
      loading: false
    };
  case types.UPDATE_CLIENTS_CONFIRMATION_TEXTS_SETTINGS_REQUEST_FAILURE:
    return {
      ...state,
      loading: false
    };
  case types.UPDATE_CLIENTS_ENABLED_LOANS_SETTINGS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.UPDATE_CLIENTS_ENABLED_LOANS_SETTINGS_REQUEST_SUCCESS:
    return {
      ...state,
      list: action.payload.data,
      loading: false
    };
  case types.UPDATE_CLIENTS_ENABLED_LOANS_SETTINGS_REQUEST_FAILURE:
    return {
      ...state,
      loading: false
    };
  case types.UPDATE_AUTO_APPROVE_LOAN_TYPES_SETTINGS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.UPDATE_AUTO_APPROVE_LOAN_TYPES_SETTINGS_REQUEST_SUCCESS:
    return {
      ...state,
      list: action.payload.data,
      loading: false
    };
  case types.UPDATE_AUTO_APPROVE_LOAN_TYPES_SETTINGS_REQUEST_FAILURE:
    return {
      ...state,
      loading: false
    };
  default:
    return state;
  }
};

export default clientsSettings;

