import {put, select, takeEvery} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import * as types from './types';

import r from '../../config/routes';
import {getClientsUsers} from './actions';
import {showConfirmationInfo} from "../app-state/actions";

function* afterCreateClientUserSuccess() {
  const state = yield select();

  yield put(push(`${r.ClientsEdit.replace(':client_id', localStorage.getItem('AUTH_CLIENT_ID'))}#users`));
  yield put(getClientsUsers(state.clients.client.id));
  yield put(showConfirmationInfo('Successfully created User'))
}

function* afterUpdateClientUserSuccess() {
  const state = yield select();

  yield put(push(`${r.ClientsEdit.replace(':client_id', state.clientsUsers.clientId)}#users`));
  yield put(getClientsUsers(state.clientsUsers.clientId));
  yield put(showConfirmationInfo('Successfully updated User'))
}

function* afterResendConfirmationClientUserSuccess() {
  const state = yield select();

  yield put(push(`${r.ClientsEdit.replace(':client_id', state.clientsUsers.clientId)}#users`));
  yield put(getClientsUsers(state.clientsUsers.clientId));
  yield put(showConfirmationInfo('Success!'))
}

export default function* authSaga() {
  yield takeEvery(types.CREATE_CLIENTS_USERS_REQUEST_SUCCESS, afterCreateClientUserSuccess);
  yield takeEvery(types.UPDATE_CLIENTS_USERS_REQUEST_SUCCESS, afterUpdateClientUserSuccess);
  yield takeEvery(types.RESEND_CONFIRMATION_CLIENTS_USERS_REQUEST_SUCCESS, afterResendConfirmationClientUserSuccess);
}
