import {connect} from 'react-redux';
import Component from './component';

import compose from 'recompose/compose';
import withTheme from '@material-ui/styles/withTheme';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';

import {toggleSidenav} from '../../../../actions/layout.actions';
import {logout} from '../../../../redux/auth/actions';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  layout: {
    sidenavOpen: state.layout.sidenavOpen
  },
  isWidthDown,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  toggleSidenav: () => dispatch(toggleSidenav()),
});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(Component)

export default withTheme(compose(
  withWidth(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Component));
