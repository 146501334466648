"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.statsDashboard = exports.updateLenderUsersNotificationSettings = exports.updateLenderUserName = exports.fetchLenderUser = exports.convertCurrency = exports.lenderSetMMoneyWalletURL = exports.getTermsAndConditions = exports.setLendingOptions = exports.lendersShowOne = exports.lendersWalletShowOne = exports.lendersWalletMarkDepositAsDelivered = exports.lendersWalletMakeDeposit = exports.lendersWalletMakeWithdrawal = exports.fetchLendersWalletTotals = exports.fetchLendersWallet = exports.fetchLendersLoan = exports.fetchLoans = exports.getLoanQRCode = exports.getClientsUser = exports.getInstantpayQRCode = exports.markPaidLoansInstallment = exports.markDeliveredLoan = exports.markRepaidLoan = exports.denyLoan = exports.approveLoan = exports.getLoans = exports.getLoan = exports.getInstantPay = exports.markDeliveredSelected = exports.markRepaidPaymentRequests = exports.denyPaymentRequests = exports.approvePaymentRequests = exports.getPaymentRequests = exports.getClient = exports.registerFcmToken = exports.resetPassword = exports.fetchLenderProfile = exports.requestResetLink = exports.registerConfirm = exports.login = exports.setResponseInterceptor = void 0;

var _axios = _interopRequireDefault(require("axios"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
/* eslint-disable import/prefer-default-export */


var instance = _axios["default"].create({
  baseURL: process.env.REACT_APP_API_URL
});

var config = function config() {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: "Bearer ".concat(localStorage.getItem('AUTH_TOKEN'))
    }
  };
};

var setResponseInterceptor = function setResponseInterceptor(_ref) {
  var success = _ref.success,
      failure = _ref.failure;
  instance.interceptors.response.use(success, failure);
};

exports.setResponseInterceptor = setResponseInterceptor;

var login = function login(username, password, role) {
  return instance.post('auth/login', {
    username: username,
    password: password,
    role: role
  }, config());
};

exports.login = login;

var registerConfirm = function registerConfirm(username, password, code) {
  return instance.post('auth/signup-confirm', {
    username: username,
    password: password,
    confirmation_code: code
  }, config());
};

exports.registerConfirm = registerConfirm;

var requestResetLink = function requestResetLink(username) {
  return instance.post('/auth/forgot-password', {
    username: username
  });
};

exports.requestResetLink = requestResetLink;

var fetchLenderProfile = function fetchLenderProfile() {
  return instance.post('/profile', config);
};

exports.fetchLenderProfile = fetchLenderProfile;

var resetPassword = function resetPassword(email, code, password) {
  return instance.post('/auth/forgot-password-confirm', {
    username: email,
    password: password,
    confirmation_code: code
  });
};

exports.resetPassword = resetPassword;

var registerFcmToken = function registerFcmToken(clientId, userId, token) {
  return instance.post("/clients-users/".concat(clientId, "/users/").concat(userId, "/fcm-registrations"), {
    fcm_registration_token: token
  }, config());
};

exports.registerFcmToken = registerFcmToken;

var getClient = function getClient(clientId) {
  return instance.get("/clients/".concat(clientId), config);
};
/* Instant Payroll */


exports.getClient = getClient;

var getPaymentRequests = function getPaymentRequests(clientId) {
  return instance.get("/clients/".concat(clientId, "/payment-requests"), config());
};

exports.getPaymentRequests = getPaymentRequests;

var approvePaymentRequests = function approvePaymentRequests(clientId, id) {
  return instance.post("/clients/".concat(clientId, "/approve-payment-requests"), {
    loan_id: id
  }, config());
};

exports.approvePaymentRequests = approvePaymentRequests;

var denyPaymentRequests = function denyPaymentRequests(clientId, ids) {
  return instance.post("/clients/".concat(clientId, "/deny-payment-requests"), {
    ids: ids
  }, config());
};

exports.denyPaymentRequests = denyPaymentRequests;

var markRepaidPaymentRequests = function markRepaidPaymentRequests(clientId, ids) {
  return instance.post("/clients/".concat(clientId, "/mark-repaid-payment-requests"), {
    ids: ids
  }, config());
};

exports.markRepaidPaymentRequests = markRepaidPaymentRequests;

var markDeliveredSelected = function markDeliveredSelected(clientId, ids) {
  return instance.post("/clients/".concat(clientId, "/deliver-payment-requests"), {
    ids: ids
  }, config());
};

exports.markDeliveredSelected = markDeliveredSelected;

var getInstantPay = function getInstantPay(clientId, instantpayId) {
  return instance.get("/clients/".concat(clientId, "/payment-requests/").concat(instantpayId), config());
};

exports.getInstantPay = getInstantPay;

var getLoan = function getLoan(clientId, loanId) {
  return instance.get("/clients-loans/".concat(clientId, "/traditional-loans/").concat(loanId), config());
};

exports.getLoan = getLoan;

var getLoans = function getLoans(clientId) {
  return instance.get("/clients-loans/".concat(clientId, "/traditional-loans"), config());
};

exports.getLoans = getLoans;

var approveLoan = function approveLoan(clientId, id) {
  return instance.post("/clients-loans/".concat(clientId, "/approve-traditional-loans"), {
    loan_id: id
  }, config());
};

exports.approveLoan = approveLoan;

var denyLoan = function denyLoan(clientId, id) {
  return instance.post("/clients-loans/".concat(clientId, "/deny-traditional-loans"), {
    ids: [id]
  }, config());
};

exports.denyLoan = denyLoan;

var markRepaidLoan = function markRepaidLoan(clientId, ids) {
  return instance.post("/clients-loans/".concat(clientId, "/mark-repaid-traditional-loans"), {
    ids: ids
  }, config());
};

exports.markRepaidLoan = markRepaidLoan;

var markDeliveredLoan = function markDeliveredLoan(clientId, ids) {
  return instance.post("/clients-loans/".concat(clientId, "/deliver-traditional-loans"), {
    ids: ids
  }, config());
};

exports.markDeliveredLoan = markDeliveredLoan;

var markPaidLoansInstallment = function markPaidLoansInstallment(clientId, loanId, installmentDate) {
  return instance.patch("/clients-loans/".concat(clientId, "/traditional-loans/").concat(loanId, "/installments/").concat(installmentDate), {
    is_paid: true
  }, config());
};

exports.markPaidLoansInstallment = markPaidLoansInstallment;

var getInstantpayQRCode = function getInstantpayQRCode(clientId, instantpayId) {
  return instance.get("/clients/".concat(clientId, "/payment-requests/").concat(instantpayId, "/qrcode.png"), config());
};

exports.getInstantpayQRCode = getInstantpayQRCode;

var getClientsUser = function getClientsUser(clientId, clientUserId) {
  return instance.get("/clients-users/".concat(clientId, "/users/").concat(clientUserId), config());
};

exports.getClientsUser = getClientsUser;

var getLoanQRCode = function getLoanQRCode(clientId, loanId) {
  return instance.get("/clients-loans/".concat(clientId, "/traditional-loans/").concat(loanId, "/qrcode.png"), config());
};

exports.getLoanQRCode = getLoanQRCode;

var fetchLoans = function fetchLoans(lenderId) {
  return instance.get("/lenders-loans/".concat(lenderId, "/all"), config());
};

exports.fetchLoans = fetchLoans;

var fetchLendersLoan = function fetchLendersLoan(lenderId, loanId, type) {
  return instance.get("/lenders-loans/".concat(lenderId, "/").concat(type, "/").concat(loanId), config());
};

exports.fetchLendersLoan = fetchLendersLoan;

var fetchLendersWallet = function fetchLendersWallet(lenderId) {
  return instance.get("/lenders-wallets/".concat(lenderId, "/all"), config());
};

exports.fetchLendersWallet = fetchLendersWallet;

var fetchLendersWalletTotals = function fetchLendersWalletTotals(lenderId) {
  return instance.get("/lenders-wallets/".concat(lenderId), config());
};

exports.fetchLendersWalletTotals = fetchLendersWalletTotals;

var lendersWalletMakeWithdrawal = function lendersWalletMakeWithdrawal(lenderId, amount, description) {
  return instance.post("/lenders-wallets/".concat(lenderId, "/withdrawals"), {
    amount: parseFloat(amount),
    description: description
  }, config());
};

exports.lendersWalletMakeWithdrawal = lendersWalletMakeWithdrawal;

var lendersWalletMakeDeposit = function lendersWalletMakeDeposit(lenderId, amount, description) {
  return instance.post("/lenders-wallets/".concat(lenderId, "/deposits"), {
    amount: parseFloat(amount),
    description: description
  }, config());
};

exports.lendersWalletMakeDeposit = lendersWalletMakeDeposit;

var lendersWalletMarkDepositAsDelivered = function lendersWalletMarkDepositAsDelivered(lenderId, entryId) {
  return instance.patch("/lenders-wallets/".concat(lenderId, "/deposits/").concat(entryId, "/delivered"), {}, config());
};

exports.lendersWalletMarkDepositAsDelivered = lendersWalletMarkDepositAsDelivered;

var lendersWalletShowOne = function lendersWalletShowOne(lenderId, entryId) {
  return instance.get("/lenders-wallets/".concat(lenderId, "/all/").concat(entryId), config());
};

exports.lendersWalletShowOne = lendersWalletShowOne;

var lendersShowOne = function lendersShowOne(lenderId) {
  return instance.get("/lenders/".concat(lenderId), config());
}; // export const fetchLendingOptions = lenderId => instance.get(
//   `/lender-options/${lenderId}`,
//   config()
// );


exports.lendersShowOne = lendersShowOne;

var setLendingOptions = function setLendingOptions(lenderId, data) {
  return instance.patch("/lenders/".concat(lenderId, "/lending-options"), data, config());
};

exports.setLendingOptions = setLendingOptions;

var getTermsAndConditions = function getTermsAndConditions() {
  return instance.get('/super-admins/terms-and-conditions', config());
};

exports.getTermsAndConditions = getTermsAndConditions;

var lenderSetMMoneyWalletURL = function lenderSetMMoneyWalletURL(lenderId, walletUrl) {
  return instance.patch("/lenders/".concat(lenderId, "/mmoney-wallet"), {
    mmoney_wallet: walletUrl
  }, config());
};

exports.lenderSetMMoneyWalletURL = lenderSetMMoneyWalletURL;

var convertCurrency = function convertCurrency(from, to) {
  return instance.get("/currencies/".concat(from, "/").concat(to), config());
};

exports.convertCurrency = convertCurrency;

var fetchLenderUser = function fetchLenderUser(lenderId, userId) {
  return instance.get("lenders-users/".concat(lenderId, "/users/").concat(userId), config());
};

exports.fetchLenderUser = fetchLenderUser;

var updateLenderUserName = function updateLenderUserName(lenderId, userId, data) {
  return instance.patch("lenders-users/".concat(lenderId, "/users/").concat(userId, "/name"), data, config());
};

exports.updateLenderUserName = updateLenderUserName;

var updateLenderUsersNotificationSettings = function updateLenderUsersNotificationSettings(lenderId, userId, data) {
  return instance.patch("lenders-users/".concat(lenderId, "/users/").concat(userId, "/notifications"), data, config());
};

exports.updateLenderUsersNotificationSettings = updateLenderUsersNotificationSettings;

var statsDashboard = function statsDashboard(lenderId, timeframe) {
  return instance.get("lenders-stats/".concat(lenderId, "/dashboard/").concat(timeframe), config());
};

exports.statsDashboard = statsDashboard;