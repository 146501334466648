import * as types from './types';

const defaultState = {
  confirmationInfo: {
    show: false,
    message: null
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_CONFIRMATION_INFO: {
      return { ...state, confirmationInfo: { ...state.confirmationInfo, show: true, message: action.payload } };
    }
    case types.HIDE_CONFIRMATION_INFO: {
      return { ...state, confirmationInfo: { ...state.confirmationInfo, show: false, message: null } };
    }
    default:
      return state;
  }
};
