import { put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as types from './types';
import { showConfirmationInfo } from '../app-state/actions';
import r from '../../config/routes';

function* afterRegistrationSuccess() {
  const state = yield select();

  yield localStorage.setItem('AUTH_USERNAME', state.auth.username); //we'll need it for email confirmations etc.

  yield put(push(r.RegisterConfirm));
  yield put(showConfirmationInfo('Successfully registered'));
}

function* afterResetPasswordSuccess() {
  yield put(push(r.Home));
  yield put(showConfirmationInfo('Successfully sent password request'));
}

function* afterLoginSuccess() {
  const state = yield select();
  const userId = state.auth.id;
  const clientId = state.auth.role.split(':')[1];
  yield localStorage.setItem('AUTH_TOKEN', state.auth.token);
  yield localStorage.setItem('AUTH_USERNAME', state.auth.username);
  yield localStorage.setItem('AUTH_ID', userId);
  yield localStorage.setItem('AUTH_ARN', state.auth.role);
  yield localStorage.setItem('AUTH_CLIENT_ID', clientId);
  yield localStorage.setItem('AUTH_ROLE', state.auth.role.split(':')[2]);
  yield localStorage.setItem('AUTH_LOGIN_TIME', state.auth.login_time);
  yield window.location.href = r.Home; //forces browser redirect even if on r.Home
  yield put(showConfirmationInfo('Successfully logged in'));
}

function* afterLogout() {
  yield localStorage.removeItem('AUTH_TOKEN');
  yield localStorage.removeItem('AUTH_USERNAME');
  yield localStorage.removeItem('AUTH_ID');
  yield localStorage.removeItem('AUTH_ARN');
  yield localStorage.removeItem('AUTH_CLIENT_ID');
  yield localStorage.removeItem('AUTH_ROLE');
  yield localStorage.removeItem('AUTH_LOGIN_TIME');
  yield put(push(r.Home));
}

function* afterRegisterConfirm() {
  yield put(push(r.Home));
  yield put(showConfirmationInfo('Successfully confirmed account'));
}

export default function* authSaga() {
  yield takeEvery(types.REGISTER_REQUEST_SUCCESS, afterRegistrationSuccess);
  yield takeEvery(types.LOGIN_REQUEST_SUCCESS, afterLoginSuccess);
  yield takeEvery(types.LOGOUT, afterLogout);
  yield takeEvery(types.RESET_PASSWORD_REQUEST_SUCCESS, afterResetPasswordSuccess);
  yield takeEvery(types.RESET_PASSWORD_CONFIRM_REQUEST_SUCCESS, afterResetPasswordSuccess);
  yield takeEvery(types.REGISTER_CONFIRM_REQUEST_SUCCESS, afterRegisterConfirm);
}
