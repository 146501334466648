/* eslint-disable import/prefer-default-export */
import { RSAA } from 'redux-api-middleware';
import * as types from './types';

import { API_URL } from '../../config/env';

export const getSuperAdmins = () => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_SUPER_ADMINS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_SUPER_ADMINS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_SUPER_ADMINS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/super-admins`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const createSuperAdmins = data => ({
  [RSAA]: {
    types: [
      {
        type: types.CREATE_SUPER_ADMINS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_SUPER_ADMINS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_SUPER_ADMINS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/super-admins`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      username: data.username,
      password: data.password,
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone
    })
  }
});

