import { fork } from 'redux-saga/effects';
import auth from '../redux/auth/saga';
import clients from '../redux/clients/saga';
import clientsUsers from '../redux/clients-users/saga';
import superAdmins from '../redux/super-admins/saga';
import paymentRequests from '../redux/payment-requests/saga';
import loans from '../redux/loans/saga';

export default function* sagas() {
  yield fork(auth);
  yield fork(clients);
  yield fork(clientsUsers);
  yield fork(superAdmins);
  yield fork(paymentRequests);
  yield fork(loans);
}
