export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_REQUEST_SUCCESS = 'GET_CLIENTS_REQUEST_SUCCESS';
export const GET_CLIENTS_REQUEST_FAILURE = 'GET_CLIENTS_REQUEST_FAILURE';

export const GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST';
export const GET_CLIENT_REQUEST_SUCCESS = 'GET_CLIENT_REQUEST_SUCCESS';
export const GET_CLIENT_REQUEST_FAILURE = 'GET_CLIENT_REQUEST_FAILURE';

export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST';
export const CREATE_CLIENT_REQUEST_SUCCESS = 'CREATE_CLIENT_REQUEST_SUCCESS';
export const CREATE_CLIENT_REQUEST_FAILURE = 'CREATE_CLIENT_REQUEST_FAILURE';

export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENT_REQUEST_SUCCESS = 'UPDATE_CLIENT_REQUEST_SUCCESS';
export const UPDATE_CLIENT_REQUEST_FAILURE = 'UPDATE_CLIENT_REQUEST_FAILURE';

export const UPDATE_CLIENT_PAYMENT_METHOD_REQUEST = 'UPDATE_CLIENT_PAYMENT_METHOD_REQUEST';
export const UPDATE_CLIENT_PAYMENT_METHOD_REQUEST_SUCCESS = 'UPDATE_CLIENT_PAYMENT_METHOD_REQUEST_SUCCESS';
export const UPDATE_CLIENT_PAYMENT_METHOD_REQUEST_FAILURE = 'UPDATE_CLIENT_PAYMENT_METHOD_REQUEST_FAILURE';

export const SELECT_ACH_NODE_REQUEST = 'SELECT_ACH_NODE_REQUEST';
export const SELECT_ACH_NODE_REQUEST_SUCCESS = 'SELECT_ACH_NODE_REQUEST_SUCCESS';
export const SELECT_ACH_NODE_REQUEST_FAILURE = 'SELECT_ACH_NODE_REQUEST_FAILURE';

export const GET_CLIENTS_INSTANTPAY_QRCODE = 'GET_CLIENTS_INSTANTPAY_QRCODE';
export const GET_CLIENTS_INSTANTPAY_QRCODE_SUCCESS = 'GET_CLIENTS_INSTANTPAY_QRCODE_SUCCESS';
export const GET_CLIENTS_INSTANTPAY_QRCODE_FAILURE = 'GET_CLIENTS_INSTANTPAY_QRCODE_FAILURE';
