import * as types from './types';
// import _ from 'underscore';

const initial = {
  list: null,
  loan: null,
  qrcode: {
    data: null,
    loading: false,
    error: null
  },
  loading: false,
  error: null
};

const paymentRequests = (state = initial, action) => {
  switch (action.type) {
  case types.GET_INSTANTPAY_REQUEST:
    return {
      ...state,
      loan: null,
      loading: true,
      error: null
    };
  case types.GET_INSTANTPAY_REQUEST_SUCCESS:
    return {
      ...state,
      loan: action.payload.data,
      loading: false,
      error: null
    };
  case types.GET_INSTANTPAY_REQUEST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload.message
    };
  case types.GET_PAYMENT_REQUESTS_REQUEST:
    return {
      ...state,
      list: action.payload.data,
      loading: true,
      error: null
    };
  case types.GET_PAYMENT_REQUESTS_REQUEST_SUCCESS:
    return {
      ...state,
      list: (action.payload.data === null) ? [] : action.payload.data,
      loading: false,
      error: null
    };
  case types.APPROVE_PAYMENT_REQUESTS_REQUEST:
    return {
      ...state,
      error: null
    };
  case types.APPROVE_PAYMENT_REQUESTS_REQUEST_SUCCESS:
    return {
      ...state,
      error: null
    };
  case types.APPROVE_PAYMENT_REQUESTS_REQUEST_FAILURE:
    return {
      ...state,
      error: action.payload.message
    };
  case types.DENY_PAYMENT_REQUESTS_REQUEST:
    return {
      ...state,
      error: null
    };
  case types.DENY_PAYMENT_REQUESTS_REQUEST_SUCCESS:
    return {
      ...state,
      error: null
    };
  case types.DENY_PAYMENT_REQUESTS_REQUEST_FAILURE:
    return {
      ...state,
      error: action.payload.message
    };
  case types.DELETE_PAYMENT_REQUESTS_REQUEST:
    return {
      ...state,
      error: null
    };
  case types.DELETE_PAYMENT_REQUESTS_REQUEST_SUCCESS:
    return {
      ...state,
      error: null
    };
  case types.DELETE_PAYMENT_REQUESTS_REQUEST_FAILURE:
    return {
      ...state,
      error: action.payload.message
    };
  case types.MARK_REPAID_PAYMENT_REQUESTS_REQUEST:
    return {
      ...state,
      error: null
    };
  case types.MARK_REPAID_PAYMENT_REQUESTS_REQUEST_SUCCESS:
    return {
      ...state,
      error: null
    };
  case types.MARK_REPAID_PAYMENT_REQUESTS_REQUEST_FAILURE:
    return {
      ...state,
      error: action.payload.message
    };
  case types.MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST:
    return {
      ...state,
      error: null
    };
  case types.MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST_SUCCESS:
    return {
      ...state,
      error: null
    };
  case types.MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST_FAILURE:
    return {
      ...state,
      error: action.payload.message
    };
  case types.GET_INSTANTPAY_QRCODE:
    return {
      ...state,
      qrcode: {
        ...state.qrcode,
        loading: true,
        error: null,
        data: null
      }
    };
  case types.GET_INSTANTPAY_QRCODE_SUCCESS:
    return {
      ...state,
      qrcode: {
        ...state.qrcode,
        loading: false,
        error: null,
        data: action.payload.data
      }
    };
  case types.GET_INSTANTPAY_QRCODE_FAILURE:
    return {
      ...state,
      qrcode: {
        ...state.qrcode,
        loading: false,
        error: action.payload.message,
        data: null
      }
    };
  default:
    return state;
  }
};

export default paymentRequests;
