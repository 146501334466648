import React from 'react';
// Material components
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
// import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import Breadcrumbs from '../breadcrumbs/container';

const ContentToolbar = (props) => {
  const {
    width,
    layout
  } = props;
  const showBurgerMenu = props.isWidthDown('sm', width) || !layout.sidenavOpen;

  return (
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open sidenav"
        style={{ display: showBurgerMenu ? 'block' : 'none' }}
        onClick={props.toggleSidenav}
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" color="inherit" noWrap>
        <Breadcrumbs />
      </Typography>

      <span className="portal-flex" />

      {/* <IconButton
        color="inherit"
        aria-label="open notifications"
        onClick={props.logout}
      >
        <LogoutIcon />
      </IconButton> */}
    </Toolbar>
  );
};

export default ContentToolbar;
