/* eslint-disable import/prefer-default-export */
import { RSAA } from 'redux-api-middleware';
import * as types from './types';

import { API_URL } from '../../config/env';

export const getClients = () => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_CLIENTS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENTS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENTS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const getClient = clientId => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_CLIENT_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENT_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_CLIENT_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients/${clientId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const createClient = client => ({
  [RSAA]: {
    types: [
      {
        type: types.CREATE_CLIENT_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_CLIENT_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.CREATE_CLIENT_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      company_name: client.companyName,
      location: client.location,
      email: client.email,
      industry: client.industry,
      website: client.website,
      company_bio: client.bio,
      credit_limit: parseFloat(client.creditLimit)
    })
  }
});

export const updateClient = (clientId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.UPDATE_CLIENT_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_CLIENT_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_CLIENT_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients/${clientId}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      company_logo: data.companyLogo,
      company_name: data.companyName,
      location: data.location,
      email: data.email,
      industry: data.industry,
      website: data.website,
      company_bio: data.bio,
      credit_limit: parseFloat(data.creditLimit)
    })
  }
});

export const updateClientsPayroll = (clientId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.UPDATE_CLIENT_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_CLIENT_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_CLIENT_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients/${clientId}/payroll`,
    method: 'PUT',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      payroll_weekly: data.payroll_weekly,
      payroll_biweekly: data.payroll_biweekly,
      payroll_monthly: data.payroll_monthly
    })
  }
});

export const updateClientPaymentMethod = (clientId, data) => ({
  [RSAA]: {
    types: [
      {
        type: types.UPDATE_CLIENT_PAYMENT_METHOD_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_CLIENT_PAYMENT_METHOD_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.UPDATE_CLIENT_PAYMENT_METHOD_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients/${clientId}/payment-method`,
    method: 'PUT',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      plan: data.plan,
      payment_method: {
        price_per_user: parseFloat(data.enterpriseLitePricePerUser),
        interest_rate: parseFloat(data.enterpriseLiteInterestRate),
        monthly_subscription: parseFloat(data.enterpriseMonthlySubscription)
      }
    })
  }
});

export const getInstantpayQRCode = (clientId, instantpayId) => {
  return {
    [RSAA]: {
      types: [
        {
          type: types.GET_CLIENTS_INSTANTPAY_QRCODE,
          payload: (_action, _state, res) => res.json()
        },
        {
          type: types.GET_CLIENTS_INSTANTPAY_QRCODE_SUCCESS,
          payload: (_action, _state, res) => res.json()
        },
        {
          type: types.GET_CLIENTS_INSTANTPAY_QRCODE_FAILURE,
          payload: (_action, _state, res) => res.json()
        }
      ],
      endpoint: `${API_URL}/clients/${clientId}/payment-requests/${instantpayId}/qrcode.png`,
      method: 'GET',
      headers: {
        'Content-Type': 'applicaiton/json',
        Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
      },
    }
  };
};