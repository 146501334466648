export const GET_LOAN_QRCODE = 'GET_LOAN_QRCODE';
export const GET_LOAN_QRCODE_SUCCESS = 'GET_LOAN_QRCODE_SUCCESS';
export const GET_LOAN_QRCODE_FAILURE = 'GET_LOAN_QRCODE_FAILURE';

export const GET_LOAN_REQUEST = 'GET_LOAN_REQUEST';
export const GET_LOAN_REQUEST_SUCCESS = 'GET_LOAN_REQUEST_SUCCESS';
export const GET_LOAN_REQUEST_FAILURE = 'GET_LOAN_REQUEST_FAILURE';

export const GET_LOANS_REQUEST = 'GET_LOANS_REQUEST';
export const GET_LOANS_REQUEST_SUCCESS = 'GET_LOANS_REQUEST_SUCCESS';
export const GET_LOANS_REQUEST_FAILURE = 'GET_LOANS_REQUEST_FAILURE';

export const APPROVE_LOANS_REQUEST = 'APPROVE_LOANS_REQUEST';
export const APPROVE_LOANS_REQUEST_SUCCESS = 'APPROVE_LOANS_REQUEST_SUCCESS';
export const APPROVE_LOANS_REQUEST_FAILURE = 'APPROVE_LOANS_REQUEST_FAILURE';

export const DENY_LOANS_REQUEST = 'DENY_LOANS_REQUEST';
export const DENY_LOANS_REQUEST_SUCCESS = 'DENY_LOANS_REQUEST_SUCCESS';
export const DENY_LOANS_REQUEST_FAILURE = 'DENY_LOANS_REQUEST_FAILURE';

export const DELETE_LOANS_REQUEST = 'DELETE_LOANS_REQUEST';
export const DELETE_LOANS_REQUEST_SUCCESS = 'DELETE_LOANS_REQUEST_SUCCESS';
export const DELETE_LOANS_REQUEST_FAILURE = 'DELETE_LOANS_REQUEST_FAILURE';

export const MARK_REPAID_LOANS_REQUEST = 'MARK_REPAID_LOANS_REQUEST';
export const MARK_REPAID_LOANS_REQUEST_SUCCESS = 'MARK_REPAID_LOANS_REQUEST_SUCCESS';
export const MARK_REPAID_LOANS_REQUEST_FAILURE = 'MARK_REPAID_LOANS_REQUEST_FAILURE';

export const MARK_DELIVERED_LOANS_REQUEST = 'MARK_DELIVERED_LOANS_REQUEST';
export const MARK_DELIVERED_LOANS_REQUEST_SUCCESS = 'MARK_DELIVERED_LOANS_REQUEST_SUCCESS';
export const MARK_DELIVERED_LOANS_REQUEST_FAILURE = 'MARK_DELIVERED_LOANS_REQUEST_FAILURE';

export const MARK_INSTALLMENT_AS_PAID_REQUEST = 'MARK_INSTALLMENT_AS_PAID_REQUEST';
export const MARK_INSTALLMENT_AS_PAID_REQUEST_SUCCESS = 'MARK_INSTALLMENT_AS_PAID_REQUEST_SUCCESS';
export const MARK_INSTALLMENT_AS_PAID_REQUEST_FAILURE = 'MARK_INSTALLMENT_AS_PAID_REQUEST_FAILURE';
