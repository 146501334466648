import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
// import registerServiceWorker from './registerServiceWorker';
import WebFont from 'webfontloader';
import { createBrowserHistory } from 'history';
import { apiMiddleware } from 'redux-api-middleware';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';

// import { BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';

import { initFirebase } from '@paymachine/shared/lib/utils/push-notifications';
import {
  FCM_apiKey,
  FCM_authDomain,
  FCM_databaseURL,
  FCM_projectId,
  FCM_storageBucket,
  FCM_messagingSenderId,
  FCM_appId
} from './config/env';

import sagas from './sagas/index';
import rootReducer from './reducers/index';
import './index.css';
import App from './app.component';

WebFont.load({
  google: {
    families: ['Poppins:300,400,400i,500,600,700']
  }
});

const firebaseConfig = {
  apiKey: FCM_apiKey,
  authDomain: FCM_authDomain,
  databaseURL: FCM_databaseURL,
  projectId: FCM_projectId,
  storageBucket: FCM_storageBucket,
  messagingSenderId: FCM_messagingSenderId,
  appId: FCM_appId
};
initFirebase(firebaseConfig);

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(
  routerMiddleware(history),
  apiMiddleware,
  sagaMiddleware,
);

const store = createStore(
  rootReducer(history),
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools({})(middleware)
    : middleware
);
sagaMiddleware.run(sagas);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
// registerServiceWorker();
