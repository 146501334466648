export const UPDATE_CLIENTS_CONFIRMATION_TEXTS_SETTINGS_REQUEST = 'UPDATE_CLIENTS_SETTINGS_REQUEST';
export const UPDATE_CLIENTS_CONFIRMATION_TEXTS_SETTINGS_REQUEST_SUCCESS = 'UPDATE_CLIENTS_SETTINGS_REQUEST_SUCCESS';
export const UPDATE_CLIENTS_CONFIRMATION_TEXTS_SETTINGS_REQUEST_FAILURE = 'UPDATE_CLIENTS_SETTINGS_REQUEST_FAILURE';

export const UPDATE_CLIENTS_ENABLED_LOANS_SETTINGS_REQUEST = 'UPDATE_CLIENTS_ENABLED_LOANS_SETTINGS_REQUEST';
export const UPDATE_CLIENTS_ENABLED_LOANS_SETTINGS_REQUEST_SUCCESS = 'UPDATE_CLIENTS_ENABLED_LOANS_SETTINGS_REQUEST_SUCCESS';
export const UPDATE_CLIENTS_ENABLED_LOANS_SETTINGS_REQUEST_FAILURE = 'UPDATE_CLIENTS_ENABLED_LOANS_SETTINGS_REQUEST_FAILURE';

export const UPDATE_AUTO_APPROVE_LOAN_TYPES_SETTINGS_REQUEST = 'UPDATE_AUTO_APPROVE_LOAN_TYPES_SETTINGS_REQUEST';
export const UPDATE_AUTO_APPROVE_LOAN_TYPES_SETTINGS_REQUEST_SUCCESS = 'UPDATE_AUTO_APPROVE_LOAN_TYPES_SETTINGS_REQUEST_SUCCESS';
export const UPDATE_AUTO_APPROVE_LOAN_TYPES_SETTINGS_REQUEST_FAILURE = 'UPDATE_AUTO_APPROVE_LOAN_TYPES_SETTINGS_REQUEST_FAILURE';
