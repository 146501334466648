import * as types from './types';
// import _ from 'underscore';

const initial = {
  client_id: null,
  client: null,
  list: [],
  message: null, // {msg: 'User authenticated', type: 'info'}
  loading: false
};

const clients = (state = initial, action) => {
  switch (action.type) {
  case types.GET_CLIENTS_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.GET_CLIENTS_REQUEST_SUCCESS:
    return {
      ...state,
      list: action.payload.data,
      loading: false
    };
  case types.GET_CLIENTS_REQUEST_FAILURE:
    return {
      ...state,
      loading: false
    };
  case types.CREATE_CLIENT_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.CREATE_CLIENT_REQUEST_SUCCESS:
    return {
      ...state,
      client_id: action.payload.data,
      client: null,
      loading: false
    };
  case types.CREATE_CLIENT_REQUEST_FAILURE:
    return {
      ...state,
      loading: false
    };
  case types.GET_CLIENT_REQUEST_SUCCESS:
    return {
      ...state,
      client: action.payload.data,
      loading: false
    };
  case types.UPDATE_CLIENT_REQUEST:
    return {
      ...state,
      loading: true
    };
  case types.UPDATE_CLIENT_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false
    };
  case types.UPDATE_CLIENT_REQUEST_FAILURE:
    return {
      ...state,
      loading: false
    };
  case types.GET_CLIENTS_INSTANTPAY_QRCODE:
    return {
      ...state,
      qrcode: null,
      loading: true
    };
  case types.GET_CLIENTS_INSTANTPAY_QRCODE_SUCCESS:
    return {
      ...state,
      qrcode: action.payload.data,
      loading: false
    };
  case types.GET_CLIENTS_INSTANTPAY_QRCODE_FAILURE:
    return {
      ...state,
      loading: false
    };
  default:
    return state;
  }
};

export default clients;

