import React from 'react';
import PropTypes from 'prop-types';

import logoImage from '../../assets/images/paymachine.svg';
import './style.scss';

const NoLayout = (props) => {
  const {
    children
  } = props;
  return (
    <div className="container">
      <div className="logo">
        <a href="/">
          <img src={logoImage} className="logo-image" alt="logo" />
          <span>
            PayMachine
          </span>
        </a>
      </div>
      {children}
    </div>
  );
};

NoLayout.propTypes = {
  children: PropTypes.shape({}).isRequired
};

export default NoLayout;

