import { put, takeEvery, select } from 'redux-saga/effects';
import * as types from './types';
import { getLoans, getLoan } from './actions';
import { showConfirmationInfo } from "../app-state/actions";

function* afterStatusUpdate() {
  // yield put(push(r.PaymentRequestsList));
  yield put(getLoans(localStorage.getItem('AUTH_CLIENT_ID')));
  yield put(showConfirmationInfo('Success!'))
}

function* fetchCurrentLoan() {
  const state = yield select();

  yield put(getLoan(localStorage.getItem('AUTH_CLIENT_ID'), state.loans.loan.id));
}

export default function* loans() {
  yield takeEvery(types.APPROVE_LOANS_REQUEST_SUCCESS, afterStatusUpdate);
  yield takeEvery(types.DENY_LOANS_REQUEST_SUCCESS, afterStatusUpdate);
  yield takeEvery(types.DELETE_LOANS_REQUEST_SUCCESS, afterStatusUpdate);
  yield takeEvery(types.MARK_REPAID_LOANS_REQUEST_SUCCESS, afterStatusUpdate);
  yield takeEvery(types.MARK_DELIVERED_LOANS_REQUEST_SUCCESS, afterStatusUpdate);
  
  yield takeEvery(types.MARK_INSTALLMENT_AS_PAID_REQUEST_SUCCESS, fetchCurrentLoan);
}
