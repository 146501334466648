export const GET_PAYMENT_REQUESTS_REQUEST = 'GET_PAYMENT_REQUESTS_REQUEST';
export const GET_PAYMENT_REQUESTS_REQUEST_SUCCESS = 'GET_PAYMENT_REQUESTS_REQUEST_SUCCESS';
export const GET_PAYMENT_REQUESTS_REQUEST_FAILURE = 'GET_PAYMENT_REQUESTS_REQUEST_FAILURE';

export const APPROVE_PAYMENT_REQUESTS_REQUEST = 'APPROVE_PAYMENT_REQUESTS_REQUEST';
export const APPROVE_PAYMENT_REQUESTS_REQUEST_SUCCESS = 'APPROVE_PAYMENT_REQUESTS_REQUEST_SUCCESS';
export const APPROVE_PAYMENT_REQUESTS_REQUEST_FAILURE = 'APPROVE_PAYMENT_REQUESTS_REQUEST_FAILURE';

export const DENY_PAYMENT_REQUESTS_REQUEST = 'DENY_PAYMENT_REQUESTS_REQUEST';
export const DENY_PAYMENT_REQUESTS_REQUEST_SUCCESS = 'DENY_PAYMENT_REQUESTS_REQUEST_SUCCESS';
export const DENY_PAYMENT_REQUESTS_REQUEST_FAILURE = 'DENY_PAYMENT_REQUESTS_REQUEST_FAILURE';

export const DELETE_PAYMENT_REQUESTS_REQUEST = 'DELETE_PAYMENT_REQUESTS_REQUEST';
export const DELETE_PAYMENT_REQUESTS_REQUEST_SUCCESS = 'DELETE_PAYMENT_REQUESTS_REQUEST_SUCCESS';
export const DELETE_PAYMENT_REQUESTS_REQUEST_FAILURE = 'DELETE_PAYMENT_REQUESTS_REQUEST_FAILURE';

export const MARK_REPAID_PAYMENT_REQUESTS_REQUEST = 'MARK_REPAID_PAYMENT_REQUESTS_REQUEST';
export const MARK_REPAID_PAYMENT_REQUESTS_REQUEST_SUCCESS = 'MARK_REPAID_PAYMENT_REQUESTS_REQUEST_SUCCESS';
export const MARK_REPAID_PAYMENT_REQUESTS_REQUEST_FAILURE = 'MARK_REPAID_PAYMENT_REQUESTS_REQUEST_FAILURE';

export const MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST = 'MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST';
export const MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST_SUCCESS = 'MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST_SUCCESS';
export const MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST_FAILURE = 'MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST_FAILURE';

export const GET_INSTANTPAY_QRCODE = 'GET_INSTANTPAY_QRCODE';
export const GET_INSTANTPAY_QRCODE_SUCCESS = 'GET_INSTANTPAY_QRCODE_SUCCESS';
export const GET_INSTANTPAY_QRCODE_FAILURE = 'GET_INSTANTPAY_QRCODE_FAILURE';

export const GET_INSTANTPAY_REQUEST = 'GET_INSTANTPAY_REQUEST';
export const GET_INSTANTPAY_REQUEST_SUCCESS = 'GET_INSTANTPAY_REQUEST_SUCCESS';
export const GET_INSTANTPAY_REQUEST_FAILURE = 'GET_INSTANTPAY_REQUEST_FAILURE';
