/* eslint-disable import/prefer-default-export */
import { RSAA } from 'redux-api-middleware';
import * as types from './types';

import { API_URL } from '../../config/env';

export const getLoan = (clientId, loanId) => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_LOAN_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_LOAN_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_LOAN_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-loans/${clientId}/traditional-loans/${loanId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const getLoans = clientId => ({
  [RSAA]: {
    types: [
      {
        type: types.GET_LOANS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_LOANS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.GET_LOANS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-loans/${clientId}/traditional-loans`,
    method: 'GET',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  }
});

export const approveSelected = (clientId, id) => ({
  [RSAA]: {
    types: [
      {
        type: types.APPROVE_LOANS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.APPROVE_LOANS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.APPROVE_LOANS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-loans/${clientId}/approve-traditional-loans`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      loan_id: id
    })
  }
});

export const denySelected = (clientId, ids) => ({
  [RSAA]: {
    types: [
      {
        type: types.DENY_LOANS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.DENY_LOANS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.DENY_LOANS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-loans/${clientId}/deny-traditional-loans`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      ids
    })
  }
});

export const deleteSelected = (clientId, ids) => ({
  [RSAA]: {
    types: [
      {
        type: types.DELETE_LOANS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.DELETE_LOANS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.DELETE_LOANS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-loans/${clientId}/traditional-loans`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      ids
    })
  }
});

export const markRepaidSelected = (clientId, ids) => ({
  [RSAA]: {
    types: [
      {
        type: types.MARK_REPAID_LOANS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.MARK_REPAID_LOANS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.MARK_REPAID_LOANS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-loans/${clientId}/mark-repaid-traditional-loans`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      ids
    })
  }
});

export const markDeliveredSelected = (clientId, ids) => ({
  [RSAA]: {
    types: [
      {
        type: types.MARK_DELIVERED_LOANS_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.MARK_DELIVERED_LOANS_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.MARK_DELIVERED_LOANS_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-loans/${clientId}/deliver-traditional-loans`,
    method: 'POST',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      ids
    })
  }
});

export const getLoanQRCode = (clientId, loanId) => {
  return {
    [RSAA]: {
      types: [
        {
          type: types.GET_LOAN_QRCODE,
          payload: (_action, _state, res) => res.json()
        },
        {
          type: types.GET_LOAN_QRCODE_SUCCESS,
          payload: (_action, _state, res) => res.json()
        },
        {
          type: types.GET_LOAN_QRCODE_FAILURE,
          payload: (_action, _state, res) => res.json()
        }
      ],
      endpoint: `${API_URL}/clients-loans/${clientId}/traditional-loans/${loanId}/qrcode.png`,
      method: 'GET',
      headers: {
        'Content-Type': 'applicaiton/json',
        Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
      },
    }
  };
};

export const markInstallmentAsPaid = (clientId, loanId, installmentDate) => ({
  [RSAA]: {
    types: [
      {
        type: types.MARK_INSTALLMENT_AS_PAID_REQUEST,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.MARK_INSTALLMENT_AS_PAID_REQUEST_SUCCESS,
        payload: (_action, _state, res) => res.json()
      },
      {
        type: types.MARK_INSTALLMENT_AS_PAID_REQUEST_FAILURE,
        payload: (_action, _state, res) => res.json()
      }
    ],
    endpoint: `${API_URL}/clients-loans/${clientId}/traditional-loans/${loanId}/installments/${installmentDate}`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'applicaiton/json',
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    },
    body: JSON.stringify({
      is_paid: true
    })
  }
});
