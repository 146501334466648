import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';

import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import v from 'validate.js';
import _ from 'underscore';
import { hasErrors, printErrors } from '@paymachine/shared/lib/validation';

import scss from './login.module.scss';
import { themes } from '../../../themes';

import logoImage from '../../../assets/images/paymachine.svg';

const Login = (props) => {
  const {
    classes,
    width
  } = props;
  const [auth, setAuth] = useState({ username: null, password: null });
  const [errors, setErrors] = useState({
    username: null,
    password: null
  });
  const constraints = {
    username: {
      presence: true,
      email: true
    },
    password: {
      presence: true
    }
  };

  useEffect(() => {
    if (props.auth.message !== null) {
      setErrors({
        ...errors,
        password: [props.auth.message],
      });
    }
  }, [props.auth.message]);

  const handleLogin = (e) => {
    e.preventDefault();
    const ee = v.validate(auth, constraints);
    if (!_.isEmpty(ee)) {
      setErrors({
        ...ee
      });
      return;
    }

    setErrors({});
    props.login(auth.username, auth.password);
  };

  // Flip container to column on mobile screens.
  const panelDirection = width === 'xs' ? 'column' : 'row';

  return (
    <Grid
      container
      direction="row"
      spacing={0}
      justify="center"
      alignItems="center"
      className={classes.background}
    >
      <Grid item md={7} sm={10} xs={12} className={scss.panel}>
        <Grid direction={panelDirection} container spacing={0}>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <Card className={classNames(scss.card, classes['primary-card'])}>
              <CardContent className={classNames(scss['signup-content'], scss.cardContent)}>
                <Typography className={scss['signup-logo-text']} variant="h5" component="h2" gutterBottom>
                  <img src={logoImage} className={scss['signup-logo']} alt="logo" />
                  Welcome to PayMachine
                </Typography>
                <Typography className={scss['signup-content-paragraph']} component="p" gutterBottom>
                  Your journey to financial empowerment begins now. To begin enter your email address and password.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <Card className={scss.card}>
              <CardContent>
                <TextField
                  error={hasErrors(errors.username)}
                  helperText={printErrors(errors.username)}
                  label="Email Address"
                  type="email"
                  fullWidth
                  defaultValue={auth.username}
                  onChange={(e) => {
                    setAuth({
                      ...auth,
                      username: e.target.value
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                      handleLogin(e);
                    }
                  }}
                />
                <TextField
                  error={hasErrors(errors.password)}
                  helperText={printErrors(errors.password)}
                  label="Password"
                  fullWidth
                  margin="normal"
                  type="password"
                  defaultValue={auth.password}
                  onChange={(e) => {
                    setAuth({
                      ...auth,
                      password: e.target.value
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      e.stopPropagation();
                      handleLogin(e);
                    }
                  }}
                />
              </CardContent>
              <CardActions className={scss['login-actions']}>
                <Button color="secondary" variant="contained" onClick={handleLogin}>Login</Button>
                <Button href="/forgot-password">Forgot Password</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Login.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  width: PropTypes.string.isRequired
};

export default compose(withWidth(), withStyles(themes.theme.authTheme, { withTheme: true }))(Login);
