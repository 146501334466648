import * as types from './types';
// import _ from 'underscore';

const initial = {
  qrcode: null,
  loan: null,
  list: null,
  error: null,
  qrcodeError: null,
  loading: false
};

const loans = (state = initial, action) => {
  switch (action.type) {
  case types.GET_LOAN_REQUEST:
    return {
      ...state,
      loan: null,
      loading: true,
      error: null
    };
  case types.GET_LOAN_REQUEST_SUCCESS:
    return {
      ...state,
      loan: action.payload.data,
      loading: false,
      error: null
    };
  case types.GET_LOAN_REQUEST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload.message
    };
  case types.GET_LOANS_REQUEST:
    return {
      ...state,
      list: action.payload.data,
      loading: true,
      error: null
    };
  case types.GET_LOANS_REQUEST_SUCCESS:
    return {
      ...state,
      list: (action.payload.data === null) ? [] : action.payload.data,
      loading: false,
      error: null
    };
  case types.APPROVE_LOANS_REQUEST:
    return {
      ...state,
      loading: true,
      error: null
    };
  case types.APPROVE_LOANS_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false,
      error: null
    };
  case types.APPROVE_LOANS_REQUEST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload.message
    };
  case types.MARK_INSTALLMENT_AS_PAID_REQUEST:
    return {
      ...state,
      loading: true,
      error: null
    };
  case types.MARK_INSTALLMENT_AS_PAID_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false,
      error: null
    };
  case types.MARK_INSTALLMENT_AS_PAID_REQUEST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload.message
    };
  case types.DENY_LOANS_REQUEST:
    return {
      ...state,
      loading: true,
      error: null
    };
  case types.DENY_LOANS_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false,
      error: null
    };
  case types.DENY_LOANS_REQUEST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload.message
    };
  case types.DELETE_LOANS_REQUEST:
    return {
      ...state,
      loading: true,
      error: null
    };
  case types.DELETE_LOANS_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false,
      error: null
    };
  case types.DELETE_LOANS_REQUEST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload.message
    };
  case types.MARK_REPAID_LOANS_REQUEST:
    return {
      ...state,
      loading: true,
      error: null
    };
  case types.MARK_REPAID_LOANS_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false,
      error: null
    };
  case types.MARK_REPAID_LOANS_REQUEST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload.message
    };
  case types.MARK_DELIVERED_LOANS_REQUEST:
    return {
      ...state,
      loading: true,
      error: null
    };
  case types.MARK_DELIVERED_LOANS_REQUEST_SUCCESS:
    return {
      ...state,
      loading: false,
      error: null
    };
  case types.MARK_DELIVERED_LOANS_REQUEST_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload.message
    };
  case types.GET_LOAN_QRCODE:
    return {
      ...state,
      qrcode: null,
      loading: true,
      qrcodeError: null
    };
  case types.GET_LOAN_QRCODE_SUCCESS:
    return {
      ...state,
      qrcode: action.payload.data,
      loading: false,
      qrcodeError: null
    };
  case types.GET_LOAN_QRCODE_FAILURE:
    return {
      ...state,
      qrcode: false,
      message: action.payload.message,
      qrcodeError: action.payload.message,
      loading: false
    };
  default:
    return state;
  }
};

export default loans;
