import { connect } from 'react-redux'
import Component from './component'
import { tryToAuthenticate } from '../../redux/auth/actions';

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  tryToAuthenticate: () => dispatch(tryToAuthenticate()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component)
