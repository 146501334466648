import {put, takeEvery} from 'redux-saga/effects';
import * as types from './types';
import {getPaymentRequests} from './actions';
import {showConfirmationInfo} from "../app-state/actions";

function* afterStatusUpdate() {
  // yield put(push(r.PaymentRequestsList));
  yield put(getPaymentRequests(localStorage.getItem('AUTH_CLIENT_ID')));
  yield put(showConfirmationInfo('Success!'))
}

export default function* paymentRequests() {
  yield takeEvery(types.APPROVE_PAYMENT_REQUESTS_REQUEST_SUCCESS, afterStatusUpdate);
  yield takeEvery(types.DENY_PAYMENT_REQUESTS_REQUEST_SUCCESS, afterStatusUpdate);
  yield takeEvery(types.DELETE_PAYMENT_REQUESTS_REQUEST_SUCCESS, afterStatusUpdate);
  yield takeEvery(types.MARK_REPAID_PAYMENT_REQUESTS_REQUEST_SUCCESS, afterStatusUpdate);
  yield takeEvery(types.MARK_DELIVERED_PAYMENT_REQUESTS_REQUEST_SUCCESS, afterStatusUpdate);
}
