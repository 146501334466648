import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'underscore';

import r from '../../config/routes';
import Login from '../auth/login/container';
import NoLayout from '../../layouts/layout-none/layout-none.component';


const styles = {
  root: {
    backgroundColor: '#ff0000'
  }
};

const TryToAuthenticate = (props) => {
  const location = useLocation();
  useEffect(() => {
    props.tryToAuthenticate();
  }, []);

  const validPaths = [
    r.Login,
    r.Register,
    r.RegisterConfirm,
    r.ForgotPassword,
    r.ForgotPasswordConfirm
  ];

  // Allow for predefined paths OR authenticated
  if (
    (_.indexOf(validPaths, location.pathname) !== -1) ||
    (location.pathname.startsWith('/register-confirm/')) ||
    (location.pathname.startsWith('/forgot-password-confirm/')) ||
    (props.auth && props.auth.authenticated)
  ) {
    return (<>{props.children }</>);
  }

  // Disallow by default
  return <NoLayout><Login styles={styles} /></NoLayout>;
};

export default TryToAuthenticate;
